/* AboutUs.css */
.about-us-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #1a1a1a;
}

.image-container {
  width: 60%;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 70%;
  border-radius: 10px;
}

.content-container {
  max-width: 500px;
}

.content-container h1 {
  font-size: 3rem;
  margin-bottom: 0;
}

.content-container .underline {
  width: 200px;
  height: 4px;
  background-color: #e94e32;
  border: none;
  margin-bottom: 20px;
}

.content-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}

h1 {
  color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about-us-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #1a1a1a;
    color: white;
    flex-direction: column;
  }

  h1 {
    margin-top: 50px;
    color: white;
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-self: center;
  }

  .image-container img {
    width: 350px;
    height: auto;
    align-self: center;
    border-radius: 10px;
  }
}
