/* src/components/Header.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 4rem;
  background-color: #222222;
  color: #fff;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.img-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 45%;
  height: auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav-menu ul li button {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  background-color: transparent;
  width: 110px
}

.active-button {
  color: #ff5733; 
  font-weight: bold;
}

.nav-menu.active {
  display: flex;
  z-index: 1000;
}

.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-menu {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #333;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .nav-menu ul {
    flex-direction: column;
    gap: 2rem;
  }

  .menu-icon {
    display: block;
  }
}
