.footer {
  background-color: #222;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-follow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-follow h3 {
  margin-bottom: 10px;
  margin-right: 50px;
  margin-left: 20px;
  margin-top: 10px;
}

.social-icons {
  display: flex;
  gap: 45px;
  line-height: 20px;
}

.social-icons a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.social-icons a:hover {
  color: #007bff; /* Or any other color you'd like */
}

.footer-copyright {
  text-align: center;
  flex-basis: 100%;
  margin-top: 10px;
  margin-right: 20px;
}

@media (min-width: 600px) {
  .footer-copyright {
    flex-basis: auto;
    margin-top: 0;
    text-align: right;
  }
}
