/* src/components/Home.css */
.home-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: url('https://example.com/your-background-image.jpg'); /* Replace with your background image URL */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    text-align: center;
    color: white;
    padding: 20px;
    max-width: 800px;
  }
  
  .content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .home-image {
    width: 100vw;
    position: absolute;
    height: 100vh;
  }
  
  /* Hide images by default */
.image-desktop,
.image-tablet,
.image-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Show desktop image on larger screens */
@media (min-width: 1024px) {
  .image-desktop {
    display: block;
  }
}

/* Show tablet image on medium-sized screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .image-tablet {
    display: block;
  }
}

/* Show mobile image on smaller screens */
@media (max-width: 767px) {
  .image-mobile {
    display: block;
  }
  .content h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
  .home-image {
    width: 100vw;
    position: absolute;
    height: 20vh;
  }
}
