/* src/components/ServicePage.css */
.service-page {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  padding-bottom: 0px;
  background-color: #121212;
  margin-bottom: 0px;
}

.service-page2 {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin-left: 15%;
  margin-right: 15%;
  background-color: #121212;
  margin-bottom: 0px;
  align-self: center;
}

.service-container {
  width: 100vw;
  background-color: #121212;
  padding-top: 60px;
  height: auto;
}

@media (max-width: 768px) {
  .service-page {
    flex-direction: column;
    align-items: center;
  }

  .service-page2 {
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    padding: 40px;
    margin-right: 0px;
    margin-top: -35px;
  }
}
