.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-family: Arial, sans-serif;
}

.info-section {
  background-color: black;
  color: white;
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 55%;
  height: 80vh;
  grid-column: auto;
  border-width: 2px;
  border-color: transparent;
}

.info-box {
  background-color: rgb(33, 32, 32);
  color: #1a1a1a;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.3s ease, border-color 0.3s ease;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-box:hover {
  transform: scale(1.05);
  border-color: #ff4500;
}

.form-section {
  background-color: #6ec5e9;
  padding: 100px;
  height: 80vh;
  width: 45%;
}

h2 {
  color: #1a1a1a;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input,
textarea {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}

button {
  background-color: #1a1a1a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #444;
}

.image-office {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
}

h4 {
  margin-bottom: 10px;
  color: white;
}

p {
  color: white;
}

input {
  height: 6vh;
}

textarea {
  height: 30vh;
}

button {
  background-color: #e94e32;
  height: 7vh;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .contact-container {
    display: flex;
    width: 100%;
    font-family: Arial, sans-serif;
    flex-direction: column;
  }

  .info-section {
    background-color: black;
    color: white;
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    width: 100%;
    height: auto;
    grid-column: auto;
    border-width: 2px;
    border-color: transparent;
  }

  .info-box {
    background-color: rgb(33, 32, 32);
    color: #1a1a1a;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: transform 0.3s ease, border-color 0.3s ease;
    border-radius: 10px;
    border: 2px solid transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .form-section {
    background-color: #6ec5e9;
    height: auto;
    width: 100%;
    padding: 20px;
  }
}
