.container {
  background-color: #000;
}

.testimonial-container {
  width: 60%;
  margin: auto;
  text-align: center;
  font-family: "Arial", sans-serif;
  padding-bottom: 100px;
  background-color: #000;
  padding-top: 50px;
}

.testimonial-item {
  padding: 20px;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #fff;
}

h5 {
  color: #fff;
}

.testimonial-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-top: 50px;
}

.testimonial-info h3 {
  font-size: 1.5em;
  font-weight: bold;
}

.testimonial-body {
  font-size: 1.2em;
}

.rating {
  margin-top: 10px;
}

.slick-dots li button:before {
  color: #000;
}

.txt-what-client {
  text-decoration-line: underline;
  font-size: 3rem;
  text-decoration-color: #e94e32;
  color: white;
  background-color: #000;
}

.txt-our-client {
  font-weight: 500;
  font-size: 1.5rem;
  line-break: 5px;
  margin-top: 70px;
}

.slick-dots li button:before {
  color: #e94e32;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: #ffffff;

}

/* for mobile devices */
@media (max-width: 768px) {
  .testimonial-container {
    width: 90%;
    margin: auto;
    text-align: center;
    font-family: "Arial", sans-serif;
  }
}
