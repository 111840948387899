/* src/components/ServiceCard.css */
.service-card {
  background-color: #1f1f1f;
  border: 2px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50vh;
  height: 50vh;
  margin-bottom: 50px;
}

.txt-services {
  width: 100vw;
  text-align: center;
  font-size: xx-large;
  border-bottom-width: 1;
  border-bottom-color: #ff4500;
  text-decoration-line: underline;
  text-decoration-color: #ff4500;
  color: #fff;
  padding-bottom: 20px;
}

.service-card:hover {
  transform: scale(1.05);
  border-color: #ff4500; /* Highlight border color on hover */
}

.service-card-img-container {
  width: 50%;
  height: 50%;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}

.service-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-card-title {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-card-description {
  font-size: 1rem;
  color: #aaa;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .service-card {
    background-color: #1f1f1f;
    border: 2px solid transparent;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease, border-color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    height: auto;
    margin-bottom: 50px;
  }

  .service-card-img-container {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .service-card-img {
    width: 120px;
    height: 120px;
    border-radius: 50px;
    object-fit: cover;
  }
}
